import React, { Component } from 'react';
import { ReactTabulator } from 'react-tabulator';

let token = sessionStorage.getItem('access_token');


export default class LabourAttendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labours: [],
      columns: [
        {title: "Labour Name", field: "labour.name"},
        {title: "Labour On Site", field: "value"},
        {title: "Price", field: "labour.price"},
        {title: "Date", field: "createdAt"},
          ]
    }
    this.updateState = this.updateState.bind(this);
    this.getLabour = this.getLabour.bind(this);
  }

  async componentDidMount(){
    await this.getLabour();
  }

  async componentDidUpdate(prevProps){
    if(this.props.projectId !== prevProps.projectId){
      await this.getLabour();
    }
  }

  updateState(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  async getLabour(){
    try {
        let response = await fetch(
          'https://cmgmt.ntceg.org/labour/' + this.props.projectId, {
            method: 'GET',
            headers: {
              'Authorization': 'Bearer ' + token,
            }
          });

          let responseJson = await response.json();
          if(responseJson.status === "SuccessFull"){
            this.setState({labours: responseJson.Results})
          }
        } catch (error) {
        console.error(error);
      }
    }

    render() {
      return (
        <div>
          <ReactTabulator
            columns={this.state.columns}
            id="labourTable"
            data={this.state.labours}
            options={{groupBy: ["createdAt"]}}
            layout={"fitData"}
            />
  </div>
      );
    }
  }
