import React, { Component } from 'react';
import { ReactTabulator } from 'react-tabulator'
let token = sessionStorage.getItem('access_token');


export default class ProgressReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: [],
      projectLabour: [],
      columns: [
        { title: "Zone Name", field: "zone.name", width: 150,visible: false},
        { title: "Phase Name", field: "phase.name"},
        { title: "Phase Progress", field: "progress", formatter:"progress"},
          ]
        }
        this.updateState = this.updateState.bind(this);
        this.getProgress = this.getProgress.bind(this);
      }

      async componentDidMount(){
        await this.getProgress();
      }

      async componentDidUpdate(prevProps){
        if(this.props.projectId !== prevProps.projectId){
          await this.getProgress();
        }
      }

      updateState(e) {
        this.setState({[e.target.name]: e.target.value});
      }


        async getProgress(){
          try {
            if(this.props.projectId !== "undefined"){
            let response = await fetch(
              'https://cmgmt.ntceg.org/reports/progress/'+ this.props.projectId.toString().split('/')[0], {
                method: 'GET',
                headers: {
                  'Authorization': 'Bearer ' + token,
                }
              });

              let responseJson = await response.json();
              if(responseJson.status === "SuccessFull"){
                this.setState({progress: responseJson.Results})
              }
            }else{
              this.setState({progress: []})
            }
            } catch (error) {
              console.error(error);
            }
          }

          render() {
            return (
              <ReactTabulator
                data={this.state.progress}
                columns={this.state.columns}
                options={{groupBy:["zone.name"],groupClosedShowCalcs:true,
                  initialSort:[
                      {column:"phase.order", dir:"asc"},
                ]}}
                layout={"fitData"}
                />
            );
          }
        }
