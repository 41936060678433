import React, { Component } from 'react';
import { ReactTabulator } from 'react-tabulator';
const token = sessionStorage.getItem('access_token');

var editCheck = function(cell){
    //cell - the cell component for the editable cell

    //get row data
    var data = cell.getRow().getData();
    if(data.username){
      return false;
    }
    else{
      return true;
    }
}


export default class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      columns: [
        {title: "User Name", field: "username",editor:"input",editable:editCheck},
        {title: "First Name", field: "firstName",editor:"input"},
        {title: "Last Name", field: "lastName",editor:"input"},
        {title: "Email", field: "email",editor:"input"},
        {title: "Phone", field: "phone",editor:"input"},
        {title: "Role", field: "role.name", editor:"select", editorParams:["superAdmin","siteEngineer","materialManager","projectCoordinator"]},
        {title: "Status", field: "status", editor:"select", editorParams:{values:["active", "incative"]}},
          ]
    };
    this.getusers = this.getusers.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.ButtonClicked = this.ButtonClicked.bind(this);
    this.getRoles = this.getRoles.bind(this);
  }

  async getRoles(){
      try {
        let response = await fetch(
          'https://cmgmt.ntceg.org/access', {
            method: 'GET',headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token,
            }
          });

          let responseJson = await response.json();
          if(responseJson.status === "SuccessFull"){
            let roles1 = [];

            responseJson.Results.forEach(function(item){
              roles1.push({label: item.name, value: item});
            });

          }
        } catch (error) {
        }

  }

  async updateUser(cell){
    try {

      if(cell.getRow().getData().firstName && cell.getRow().getData().lastName
    && cell.getRow().getData().email && cell.getRow().getData().phone && cell.getRow().getData().role.name
  && cell.getRow().getData().status){
      let response = await fetch(
        'https://cmgmt.ntceg.org/users/' + cell.getRow().getData().username, {
          method: 'POST',headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
          },
          body:JSON.stringify({
            firstName: cell.getRow().getData().firstName,
            lastName: cell.getRow().getData().lastName,
            email: cell.getRow().getData().email,
            phone: cell.getRow().getData().phone,
            role: cell.getRow().getData().role.name,
            status: cell.getRow().getData().status,
          })
        });

        let responseJson = await response.json();
        if(responseJson.status === "SuccessFull"){
          alert('User update SuccessFull')
          this.getusers();
        }
      }
      } catch (error) {
        console.error(error);
      }
  }

  async ButtonClicked(e){
    let users1 = [];
    this.state.users.forEach(function(item){
      users1.push(item);
    });
    users1.push({status:"active"});
    this.setState({ users: users1 });
  }

  async componentDidMount(){
    await this.getusers();
    await this.getRoles();

  }

  async getusers(){
    try {
      let response = await fetch(
        'https://cmgmt.ntceg.org/users/all', {
          method: 'GET',headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
          }
        });

        let responseJson = await response.json();
        if(responseJson.status === "SuccessFull"){
          this.setState({users: responseJson.Results})
        }
      } catch (error) {
        console.error(error);
      }
    }

    render() {
      return (
        <div>
          <button onClick={this.ButtonClicked} className="btn btn-primary">Add User</button>
          <hr/>
          <ReactTabulator
            columns={this.state.columns}
            id="userTable"
            data={this.state.users}
            options={{cellEdited: this.updateUser, addRowPos:"bottom"}}
            layout={"fitData"}
            />
        </div>
      );
    }
  }
