import React, { Component } from 'react';
import { ReactTabulator } from 'react-tabulator';
let token = sessionStorage.getItem('access_token');

export default class MaterialRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      materials: [],
      columns: [
      {title: "Material Name", field: "material.name",headerFilter:"input"},
      {title: "Quantity", field: "value",headerFilter:"input"},
      {title: "Requested By", field: "Requestor.firstName",headerFilter:"input"},
      {title: "Updated By", field: "Updator.firstName",headerFilter:"input"},
      {title: "Status", field: "status", editor:"select",headerFilter:true, headerFilterParams:{"Submitted":"Submitted", "Approved":"Approved","Delivered":"Delivered"}, editorParams:{values:["Submitted", "Approved","Delivered"]}},
        ],
    }
    this.updateState = this.updateState.bind(this);
    this.getMaterials = this.getMaterials.bind(this);
    this.updateRequest = this.updateRequest.bind(this);
  }

  async componentDidMount(){
    await this.getMaterials();
  }

  async componentDidUpdate(prevProps){
    if(this.props.projectId !== prevProps.projectId){
      await this.getMaterials();
    }
  }

  updateState(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  async updateRequest(cell){
    try {
      let response = await fetch(
        'https://cmgmt.ntceg.org/material/request/' + cell.getRow().getData().id, {
          method: 'POST',headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
          },
          body:JSON.stringify({
            status: cell.getRow().getData().status,
          })
        });

        let responseJson = await response.json();
        if(responseJson.status === "SuccessFull"){
          this.getMaterials();
        }
      } catch (error) {
        console.error(error);
      }
  }

  async getMaterials(){
    try {
        let response = await fetch(
          'https://cmgmt.ntceg.org/material/request/' + this.props.projectId, {
            method: 'GET',
            headers: {
              'Authorization': 'Bearer ' + token,
            }
          });

          let responseJson = await response.json();
          if(responseJson.status === "SuccessFull"){
            this.setState({materials: responseJson.Results})
          }
        } catch (error) {
        console.error(error);
      }
    }

    render() {
      return (
        <div>
          <ReactTabulator
            columns={this.state.columns}
            id="matTable"
            data={this.state.materials}
            options={{cellEdited: this.updateRequest, addRowPos:"bottom"}}
            layout={"fitData"}
            />
  </div>
      );
    }
  }
