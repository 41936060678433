import React, { Component } from 'react';
import UserList from './components/UserList'
const token = sessionStorage.getItem('access_token');


export default class UserPage extends Component {

  constructor(props) {
    super(props);
    this.state= {
      roles: [],
    };
    this.getRoles = this.getRoles.bind(this);
  }

  async componentDidMount(){
    await this.getRoles();
  }

  async getRoles(){
    try {
      let response = await fetch(
        'https://cmgmt.ntceg.org/access', {
          method: 'GET',headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
          }
        });

        let responseJson = await response.json();
        if(responseJson.status === "SuccessFull"){
          let roles1 = [];

          responseJson.Results.forEach(function(item){
            roles1.push({label: item.name, value: item});
          });
          this.setState({roles: roles1});
        }
      } catch (error) {
        console.error(error);
      }
    }
    render() {
      return (
        <div>
          <h2>User Management</h2>
          <br/>
          <UserList roles={this.state.roles}/>
        </div>
      );
    }
  }
