import React, { Component } from 'react';
import $ from 'jquery';

export default class Tree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      LoginStatus: '',
    }
    this.updateState = this.updateState.bind(this);
    this.submitProject = this.submitProject.bind(this);
  }

  updateState(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  async submitProject(e){
    if($("#loginForm")[0].checkValidity()){
      try {
        let token = sessionStorage.getItem('access_token');
        if(!token){
          let response = await fetch(
            'https://cmgmt.ntceg.org/users/login', {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                username: this.state.user,
                password: this.state.pass,
              }),
            });

            let responseJson = await response.json();
            if(responseJson.status.code === "0000"){
              sessionStorage.setItem('access_token', responseJson.access_token);
              sessionStorage.setItem('roles', JSON.stringify(responseJson.User));
              window.location.href = "/home";
            }
            else
            this.setState({LoginStatus: responseJson.message});
            $('.alert').show();
            $('.alert').on('close.bs.alert', function () {
              $('.alert').hide();
            })
          }else{
            window.location.href = "/home";
          }
        } catch (error) {
          console.error(error);
        }
      }
      else{
        $("#loginForm")[0].classList.add('was-validated');
      }

    }

    render() {
      return (
        <div className="wrapper fadeInDown">
          <div id="formContent">
            <div className="fadeIn first">
              <img src="https://betravis.github.io/icon-methods/resources/chrome.svg" width="100" height="100" id="icon" alt="User Icon" />
            </div>

            <form id="loginForm" noValidate>
                <input type="text" required name="user" onChange={this.updateState} className="fadeIn second" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Username"/>
                <input type="password" required name="pass" className="fadeIn third" onChange={this.updateState} id="exampleInputPassword1" aria-describedby="passHelp" placeholder="Password"/>
              <div className="fadeIn fourth">
                <input type="checkbox" name="check" onChange={this.updateState} className="form-check-input" id="exampleCheck1"/>
                <label className="form-check-label" for="exampleCheck1">Remember Me</label>
              </div>
            </form>
            <button id="loginButton" type="submit" className="fadeIn fourth" onClick={this.submitProject}>Log In</button>
            <div id="formFooter">
              <a className="underlineHover" href="/">Forgot Password?</a>
            </div>

          </div>
        </div>
      );
    }
  }
