import React, { Component } from 'react';
// import 'react-tabulator/lib/styles.css'; // required styles
// import 'react-tabulator/lib/css/tabulator_simple.min.css';
import { ReactTabulator } from 'react-tabulator';
const token = sessionStorage.getItem('access_token');

var editCheck = function(cell){
    //cell - the cell component for the editable cell

    //get row data
    var data = cell.getRow().getData();
    if(data.name){
      return false;
    }
    else{
      return true;
    }
}


export default class AllLabour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labours: [],
      columns: [
        {title: "Labour Name", field: "name",editor:"input",editable:editCheck},
        {title: "Price", field: "price",editor:"input"},
          ]
    };
    this.getLabour = this.getLabour.bind(this);
    this.updateLabour = this.updateLabour.bind(this);
    this.ButtonClicked = this.ButtonClicked.bind(this);
  }

  async updateLabour(cell){
    try {
      let response = await fetch(
        'https://cmgmt.ntceg.org//labour/' + cell.getRow().getData().name, {
          method: 'POST',headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
          },
          body:JSON.stringify({
            price: cell.getRow().getData().price,
          })
        });

        let responseJson = await response.json();
        if(responseJson.status === "SuccessFull"){
        }
      } catch (error) {
        console.error(error);
      }
  }

  async ButtonClicked(e){
    let labours1 = [];
    this.state.labours.forEach(function(item){
      labours1.push(item);
    });
    labours1.push({status:"active"});
    this.setState({ labours: labours1 });
  }

  async componentDidMount(){
    await this.getLabour();

  }

  async getLabour(){
    try {
      let response = await fetch(
        'https://cmgmt.ntceg.org/labour/', {
          method: 'GET',headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
          }
        });

        let responseJson = await response.json();
        if(responseJson.status === "SuccessFull"){
          this.setState({labours: responseJson.Results})
        }
      } catch (error) {
        console.error(error);
      }
    }

    render() {
      return (
        <>
          <button className="btn btn-primary" onClick={this.ButtonClicked}>Add Labour</button>
          <hr/>
          <ReactTabulator
            columns={this.state.columns}
            id="labourTable"
            data={this.state.labours}
            options={{cellEdited: this.updateLabour, addRowPos:"bottom"}}
            layout={"fitData"}
            />
        </>
      );
    }
  }
