import React, { Component } from 'react';
import AllMaterial from './components/AllMaterial'
import MaterialRequest from './components/MaterialRequest'
import MaterialStock from './components/MaterialStock'
import MaterialReport from './components/MaterialReport'
import TreeMenu from 'react-simple-tree-menu'
import {TabsContainer,Tabs,Tab} from 'react-md';
let token = sessionStorage.getItem('access_token');


export default class MaterialManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      LoginStatus: '',
      projectList: [],
      projectSelected: 0,
      projectSelectedReq: 0,
      treeData: [],
    }
    this.updateState = this.updateState.bind(this);
    this.updateState2 = this.updateState2.bind(this);
    this.getProjectList = this.getProjectList.bind(this);
    this.zoneClicked = this.zoneClicked.bind(this);
    this.loadProjectData = this.loadProjectData.bind(this);
  }

  async zoneClicked({ key, label, ...props }){
    var array = await key.split("/");
    if(props.levels){
      this.setState({projectSelected: array[0]});
    }else{
      this.setState({projectSelected: array[0] + '/' + array[array.length-1]});
    }
  }

  updateState(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  async updateState2(e) {
    this.setState({[e.target.name]: e.target.value});
    await this.loadProjectData(e.target.value);
  }

  async componentDidMount(){
    await this.getProjectList();
    //const node = ReactDOM.findDOMNode(this.refs.picker);
  }

  async loadProjectData(projectId){
    if(projectId !== "undefined"){
    let response = await fetch(
      'https://cmgmt.ntceg.org/projects/tree/'+projectId, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + token,
        }
      });
      let responseJson = await response.json();
      if(responseJson.status === "SuccessFull"){
        responseJson = JSON.stringify(responseJson).replaceAll("\"id\":", "\"key\":").replaceAll("\"name\":", "\"label\":").replaceAll("\"children\":", "\"nodes\":").replaceAll("\"zones\":", "\"nodes\":");
        console.log(responseJson);
        responseJson = await JSON.parse(responseJson);
        this.setState({treeData: [responseJson.ProjectZones]});
      }else{

      }
    }else{
      this.setState({treeData: []});
    }
  }

  async getProjectList(){
    let response = await fetch(
      'https://cmgmt.ntceg.org/projects', {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + token,
        }
      });

      let responseJson = await response.json();
      if(responseJson.status === "SuccessFull"){
        this.setState({projectList: responseJson.Projects});
      }else{

      }
  }

    render() {
      return (
        <div>
          <h2>Material Management</h2>
          <br/>
            <TabsContainer>
              <Tabs tabId="matt" colored={true}>
                <Tab label="All Material">
                  <AllMaterial/>
                </Tab>
                <Tab label="Material Request">
                  <select onChange={this.updateState} name="projectSelectedReq" className="browser-default custom-select">
                    <option value="0">Select Project</option>
                    {this.state.projectList.map(item => (<option key={item.id} value={item.id}>{item.name}</option>))}
                  </select>
                  <MaterialRequest projectId={this.state.projectSelectedReq}/>
                </Tab>
                <Tab label="Material Stock">
                  <select onChange={this.updateState} name="projectSelected" className="browser-default custom-select">
                    <option value="0">Select Project</option>
                    {this.state.projectList.map(item => (<option key={item.id} value={item.id}>{item.name}</option>))}
                  </select>
                  <MaterialStock projectId={this.state.projectSelected}/>
                </Tab>
                <Tab label="Material Report">
                  <select onChange={this.updateState2} name="projectSelected" className="browser-default custom-select">
                    <option value="0">Select Project</option>
                    {this.state.projectList.map(item => (<option key={item.id} value={item.id}>{item.name}</option>))}
                  </select>
                  {this.state.treeData.length > 0 && <TreeMenu data={this.state.treeData} onClickItem={({ key, label, ...props }) => this.zoneClicked({ key, label, ...props })}/>}
                  <MaterialReport projectId={this.state.projectSelected}/>
                </Tab>
              </Tabs>
            </TabsContainer>
        </div>
      );
    }
  }
