import React, { Component } from 'react';
import $ from 'jquery';
const token = sessionStorage.getItem('access_token');


export default class DamageReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      damages: [],
    }
    this.updateState = this.updateState.bind(this);
    this.getDamage = this.getDamage.bind(this);
    this.displayModalBoxbyData = this.displayModalBoxbyData.bind(this);
  }

  async componentDidMount(){
    await this.getDamage();
  }

  async componentDidUpdate(prevProps){
    if(this.props.projectId !== prevProps.projectId){
      await this.getDamage();
    }
  }

  updateState(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  async getDamage(){
    try {
      if(this.props.projectId !== "undefined"){
        let response = await fetch(
          'https://cmgmt.ntceg.org/reports/damage/' + this.props.projectId.toString().split('/')[0], {
            method: 'GET',
            headers: {
              'Authorization': 'Bearer ' + token,
            }
          });

          let responseJson = await response.json();
          if(responseJson.status === "SuccessFull"){
            this.setState({damages: responseJson.Results})
          }
        }else{
          this.setState({damages: []})
        }
        } catch (error) {
        console.error(error);
      }
    }

    displayModalBoxbyData(dataURI){
      let images = [];
    //let remainder = 4 - (this.state.devices % 4);
    dataURI.map((item, index) => {
      images.push(
        <img className="dmgImage"
          alt="Test"
          src={"data:image/jpg;base64," + item.image64 }
        />
      );
      return "";
    });

      this.setState({image: images});
      $('#myModal').modal('toggle');
      $("#myModal").appendTo("body");
    }

    render() {

      return (
        <div>
        <table className="table">
        <thead>
          <tr>
        <td>Zone Name</td>
        <td>Damage Date</td>
        <td>Damage Gallery</td>
        </tr>
        </thead>
        <tbody>
    {this.state.damages.map(item => (
      <tr>
        <td>{item.zone.name}</td>
        <td>{item.createdAt}</td>
        <td><a className="dmgLink" onClick={() => this.displayModalBoxbyData(item.report.imageurl)} target="_blank"><img width="100" height="100" alt="damageImage" src={"data:image/jpg;base64,"+item.report.imageurl[0].image64}/></a></td>
      </tr>
    ))}
    </tbody>
  </table>
          <div className="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered modal-lg">
      <div className="modal-content">
        <div className="modal-header">
          <button type="button" className="close" data-dismiss="modal">&times;</button>
          <h4 className="modal-title">Title</h4>
        </div>
        <div className="modal-body">
          <p>Image Notes</p>
          {this.state.image}
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>
      );
    }
  }
