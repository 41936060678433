import React, { Component } from 'react';
import { ReactTabulator } from 'react-tabulator'
let token = sessionStorage.getItem('access_token');

var nameFormatted = function(cell, formatterParams, onRendered){
    return cell.getRow().getData()["records.material.name"];
}

export default class LabourReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      material: [],
      projectLabour: [],
      columns: [
        { title: "Zone Name", field: "name", width: 150,visible: false},
        { title: "Material Name", formatter:nameFormatted},
        {title: "Material Count", field: "total"},
          ]
        }
        this.updateState = this.updateState.bind(this);
        this.getMaterial = this.getMaterial.bind(this);
      }

      async componentDidMount(){
        await this.getMaterial();
      }

      async componentDidUpdate(prevProps){
        if(this.props.projectId !== prevProps.projectId){
          await this.getMaterial();
        }
      }

      updateState(e) {
        this.setState({[e.target.name]: e.target.value});
      }

        async getMaterial(){
          try {
            if(this.props.projectId !== "undefined"){
            let response = await fetch(
              'https://cmgmt.ntceg.org/reports/material/'+ this.props.projectId, {
                method: 'GET',
                headers: {
                  'Authorization': 'Bearer ' + token,
                }
              });

              let responseJson = await response.json();
              if(responseJson.status === "SuccessFull"){
                this.setState({material: responseJson.Results})
              }
            }else{
              this.setState({material: []})
            }
            } catch (error) {
              console.error(error);
            }
          }

          render() {
            return (
              <ReactTabulator
                data={this.state.material}
                id={"MatTable"}
                columns={this.state.columns}
                options={{groupBy:["name","insertDate"],groupClosedShowCalcs:true,}}
                tooltips={true}
                layout={"fitData"}
                />
            );
          }
        }
