import React, { Component } from 'react';
import { ReactTabulator } from 'react-tabulator';
const token = sessionStorage.getItem('access_token');


var editCheck = function(cell){
    //cell - the cell component for the editable cell

    //get row data
    var data = cell.getRow().getData();
    if(data.name){
      return false;
    }
    else{
      return true;
    }

}


export default class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      materials: [],
      columns: [
        {title: "Material Name", field: "name",editor:"input",editable:editCheck},
        {title: "Unit", field: "unit",editor:"select", editorParams:{values:["m2", "m","m3"]}},
          ]
    };
    this.getmaterials = this.getmaterials.bind(this);
    this.updateMaterial = this.updateMaterial.bind(this);
    this.ButtonClicked = this.ButtonClicked.bind(this);
  }

  async updateMaterial(cell){
    try {
      let response = await fetch(
        'https://cmgmt.ntceg.org/material/' + cell.getRow().getData().name, {
          method: 'POST',headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
          },
          body:JSON.stringify({
            unit: cell.getRow().getData().unit,
          })
        });

        let responseJson = await response.json();
        if(responseJson.status === "SuccessFull"){
        }
      } catch (error) {
        console.error(error);
      }
  }

  async ButtonClicked(e){
    let materials1 = [];
    this.state.materials.forEach(function(item){
      materials1.push(item);
    });
    materials1.push({status:"active"});
    this.setState({ materials: materials1 });
  }

  async componentDidMount(){
    await this.getmaterials();

  }

  async getmaterials(){
    try {
      let response = await fetch(
        'https://cmgmt.ntceg.org/material/', {
          method: 'GET',headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
          }
        });

        let responseJson = await response.json();
        if(responseJson.status === "SuccessFull"){
          this.setState({materials: responseJson.Results})
        }
      } catch (error) {
        console.error(error);
      }
    }

    render() {
      return (
        <>
          <button className="btn btn-primary" onClick={this.ButtonClicked}>Add Material</button>
          <hr/>
          <ReactTabulator
            columns={this.state.columns}
            id="userTable"
            data={this.state.materials}
            options={{cellEdited: this.updateMaterial, addRowPos:"bottom"}}
            layout={"fitData"}
            />
        </>
      );
    }
  }
