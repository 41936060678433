import React, { Component } from 'react';
import MaterialReport from './components/MaterialReport'
import LabourReport from './components/LabourReport'
import DamageReport from './components/DamageReport'
import ProgressReport from './components/ProgressReport'
import TreeMenu from 'react-simple-tree-menu'

String.prototype.replaceAll = function(search, replacement) {
    var target = this;
    return target.replace(new RegExp(search, 'g'), replacement);
};

let token = sessionStorage.getItem('access_token');


export default class Tree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      LoginStatus: '',
      projectList: [],
      projectSelected: 0,
      treeData: [],
    }
    this.updateState = this.updateState.bind(this);
    this.getProjectList = this.getProjectList.bind(this);
    this.zoneClicked = this.zoneClicked.bind(this);
    this.loadProjectData = this.loadProjectData.bind(this);
  }

  async updateState(e) {
    this.setState({[e.target.name]: e.target.value});
    await this.loadProjectData(e.target.value);

  }

  async componentDidMount(){
    await this.getProjectList();
  }

  async zoneClicked({ key, label, ...props }){
    var array = await key.split("/");
    if(props.levels){
      this.setState({projectSelected: array[0]});
    }else{
      this.setState({projectSelected: array[0] + '/' + array[array.length-1]});
    }
  }

  async loadProjectData(projectId){
    if(projectId !== "undefined"){
    let response = await fetch(
      'https://cmgmt.ntceg.org/projects/tree/'+projectId, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + token,
        }
      });
      let responseJson = await response.json();
      if(responseJson.status === "SuccessFull"){
        responseJson = JSON.stringify(responseJson).replaceAll("\"id\":", "\"key\":").replaceAll("\"name\":", "\"label\":").replaceAll("\"children\":", "\"nodes\":").replaceAll("\"zones\":", "\"nodes\":");
        console.log(responseJson);
        responseJson = await JSON.parse(responseJson);
        this.setState({treeData: [responseJson.ProjectZones]});
      }else{

      }
    }else{
      this.setState({treeData: []});
    }
  }

  async getProjectList(){
    let response = await fetch(
      'https://cmgmt.ntceg.org/projects', {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + token,
        }
      });

      let responseJson = await response.json();
      if(responseJson.status === "SuccessFull"){
        this.setState({projectList: responseJson.Projects});
      }else{

      }
  }

    render() {
      return (
        <div>
          <h2>Project Reports</h2>
          <br/>
          <select onChange={this.updateState} name="projectSelected" className="browser-default custom-select">
            <option value="undefined">Select Project</option>
            {this.state.projectList.map(item => (<option key={item.id} value={item.id}>{item.name}</option>))}
          </select>
          {this.state.treeData.length > 0 && <TreeMenu data={this.state.treeData} onClickItem={({ key, label, ...props }) => this.zoneClicked({ key, label, ...props })}/>}
          <hr/>
          <div className="row">
            <div id="Material" className="col-6">
              <h3>Material Report</h3>
              <MaterialReport projectId={this.state.projectSelected}/>
            </div>
            <div id="Labour" className="col-6">
              <h3>Labour Report</h3>
              <LabourReport projectId={this.state.projectSelected}/>
            </div>
            </div>
            <hr style={{borderWidth: 10}}/>
            <div className="row">
            <div id="Damage" className="col-6">
              <h3>Damage Report</h3>
              <DamageReport projectId={this.state.projectSelected}/>
            </div>
            <div id="Progress" className="col-6">
              <h3>Progress Report</h3>
              <ProgressReport projectId={this.state.projectSelected}/>
            </div>
          </div>
        </div>
      );
    }
  }
