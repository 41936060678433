import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap-select/dist/css/bootstrap-select.css';
import 'bootstrap-select/dist/js/bootstrap-select.js';
import 'react-tabulator/lib/styles.css';
import 'react-md/dist/react-md.blue-light_blue.min.css';
import 'react-md';

import 'react-tabulator/lib/styles.css'; // required styles
// import 'react-tabulator/lib/css/tabulator_simple.min.css';
// import 'react-tabulator/lib/css/bulma/tabulator_bulma.min.css';
// import 'react-tabulator/lib/css/semantic-ui/tabulator_semantic-ui.min.css';
import 'react-tabulator/lib/css/bootstrap/tabulator_bootstrap4.min.css';

import * as serviceWorker from './serviceWorker';
// Your routes.js file
import MainRouter from './MainRouter';

ReactDOM.render(
  <MainRouter/>,
  document.getElementById('root')
);

//ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
