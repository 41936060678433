import React, { Component } from 'react';

export default class Logout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      LoginStatus: '',
    }
  }

  updateState(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  componentDidMount(e){
    try {
      sessionStorage.clear();
      this.props.history.push('/');
  } catch (error) {
    console.error(error);
  }
  }

  render() {
    return (
      <div className="col-12">
      </div>
      );
    }
  }
