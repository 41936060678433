import React, { Component } from 'react';
import { TabsContainer,Tabs,Tab } from 'react-md';
import Reports from './Reports';
import MaterialStock from './components/MaterialStock'
let token = sessionStorage.getItem('access_token');


export default class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      LoginStatus: '',
      projectList: [],
      projectSelected: 0,
      projectSelectedReq: 0,
    }
    this.updateState = this.updateState.bind(this);
    this.getProjectList = this.getProjectList.bind(this);
  }

  updateState(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  async componentDidMount(){
    await this.getProjectList();
    //const node = ReactDOM.findDOMNode(this.refs.picker);
  }

  async getProjectList(){
    let response = await fetch(
      'https://cmgmt.ntceg.org/projects', {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + token,
        }
      });

      let responseJson = await response.json();
      if(responseJson.status === "SuccessFull"){
        this.setState({projectList: responseJson.Projects});
      }else{

      }
  }

    render() {
      return (
        <div>
          <h2>Welcome to Construction Management</h2>
          <br/>
            <TabsContainer>
              <Tabs tabId="aw" colored={true}>
                <Tab label="Project Overview">
                  <Reports />
                </Tab>
                <Tab label="Material Stock">
                  <select onChange={this.updateState} name="projectSelected" className="browser-default custom-select">
                    <option value="0">Select Project</option>
                    {this.state.projectList.map(item => (<option key={item.id} value={item.id}>{item.name}</option>))}
                  </select>
                  <MaterialStock projectId={this.state.projectSelected}/>
                </Tab>
              </Tabs>
            </TabsContainer>
        </div>
      );
    }
  }
