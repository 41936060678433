import React, { Component } from 'react';
import { Link as RouterLink, Route } from 'react-router-dom';
import { FontIcon, ListItem } from 'react-md';

export default class NavLink extends Component {

    render() {
      if(this.props.included || this.props.to === "/Logout" || this.props.to === "/home"){
      return (
        <Route path={this.props.to} exact={this.props.exact}>
          {({ match }) => {
            let leftIcon;
            if (this.props.icon) {
              leftIcon = <FontIcon>{this.props.icon}</FontIcon>;
            }

            return (
              <ListItem
                component={RouterLink}
                active={!!match}
                to={this.props.to}
                primaryText={this.props.label}
                leftIcon={leftIcon}
              />
            );
          }
          }
        </Route>);
      }else{
          return null;
        }
    }
}
