import React from "react";
import { BrowserRouter as Router,Redirect,Switch, Route} from "react-router-dom";
import NavLink from './NavLink';
import { NavigationDrawer} from 'react-md';
/**
* Import all page components here
*/
import App from './Home';
import Projects from './ProjectDef';
import Reports from './Reports';
import Users from './User';
import Material from './MaterialManagement';
import Labour from './LabourManagement';
import Login from './Login';
import Logout from './Logout';

var CryptoJS = require("crypto-js");
var mysecret = 'P@$$w0rd';

const isAuthenticated = function (path){
  let token = sessionStorage.getItem('access_token');
  if(token){
    return true;
  }
  else return false;
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
      isAuthenticated() === true
      ? <Component {...props} />
    : <Redirect to='/' />
)} />);

/**
* All routes go here.
* Don't forget to import the components above after adding new route.
*/
function MainRouter(){
  var pages = [];
  let User = sessionStorage.getItem('roles');
  if(User){
    var roles = JSON.parse(User);
    var userPermissions = roles.role.perm;
    for(let i = 0 ; i< userPermissions.length;i++){
      if(userPermissions[i].method === "WEB"){
        pages.push(userPermissions[i].url);
      }
    }
  }

  const navItems = [{
    label: 'Home',
    to: '/home'
  },{
    label: 'Define Project',
    to: '/projects'
  },{
    label: 'Manage Material',
    to: '/material'
  },{
    label: 'Manage Labour',
    to: '/labour'
  },{
    label: 'Manage Progress',
    to: '/progress'
  },{
    label: 'Manage Damage',
    to: '/damage'
  },{
    label: 'Manage Obstacles',
    to: '/obstacles'
  },{
    label: 'View Reports',
    to: '/reports'
  },{
    label: 'Manage Users',
    to: '/users'
  },{
    label: 'Logout',
    to: '/Logout'
  }]

  return(
    <Router>
      <Switch>
        <Route path="/" exact component={Login} />
        <Route render={({ location }) => (
          <NavigationDrawer
            drawerTitle="NTC Site Management"
            toolbarTitle="Welcome to Construction Management"
            navItems={navItems.map(props => <NavLink {...props} included={pages.includes(props.to)} key={props.to} />)}
          >
          <Switch>
            <PrivateRoute path="/home" component={App} />
            <PrivateRoute path="/projects" component={Projects} />
            <PrivateRoute path="/material" component={Material} />
            <PrivateRoute path="/labour" component={Labour} />
            <PrivateRoute path="/progress" component={Labour} />
            <PrivateRoute path="/damage" component={Labour} />
            <PrivateRoute path="/reports" component={Reports} />
            <PrivateRoute path="/users" component={Users} />
            <PrivateRoute path="/Logout" component={Logout} />
          </Switch>
          </NavigationDrawer>
        )}/>
      </Switch>
    </Router>

  );
}

export default MainRouter;
