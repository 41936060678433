import React, { Component } from 'react';
import SortableTree,{addNodeUnderParent,removeNodeAtPath,changeNodeAtPath} from 'react-sortable-tree';
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app
import $ from 'jquery';

const token = sessionStorage.getItem('access_token');

export default class Tree extends Component {
  constructor(props) {
    super(props);

    this.state = {
      treeData: [],
      node: '',
      treeDepth: 0,
      levels: ['Root'],
    };
    this.addItem = this.addItem.bind(this);
    this.updateState = this.updateState.bind(this);
    this.submitProject = this.submitProject.bind(this);
    //this.getMaterials = this.getMaterials.bind(this);
  }

  async componentDidMount(){
    await this.getMaterials();
    await this.getPhases();
    await this.getLabour();
    await this.getUsers();
    //const node = ReactDOM.findDOMNode(this.refs.picker);
    $('select').selectpicker('refresh');
  }

  async submitProject(e){
    try{
      let response = await fetch(
        'https://cmgmt.ntceg.org/projects/', {
          method: 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
          },
          body: JSON.stringify({
            zones: this.state.treeData,
            name: this.state.projectName,
            levels: this.state.levels,
            labour: this.state.labourVal,
            phases: this.state.phaseVal,
            users: this.state.usersVal,
          }),
        });
        let responseJson = await response.json();
        if(responseJson.status === "SuccessFull"){
          alert("Project Successfully Defined");
        }

    }catch(err){
      alert(err);
    }
  }

  async getMaterials(){
    try {
        let response = await fetch(
          'https://cmgmt.ntceg.org/material', {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token,
            }
          });

          let responseJson = await response.json();
          if(responseJson.status === "SuccessFull"){
            this.setState({materials: responseJson.Results});
          }
        } catch (error) {
        console.error(error);
      }
    }

  async getUsers(){
        try {
            let response = await fetch(
              'https://cmgmt.ntceg.org/users', {
                method: 'GET',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + token,
                },
              });
              let responseJson = await response.json();
              if(responseJson.status === "SuccessFull"){
                this.setState({users: responseJson.Results});
              }
            } catch (error) {
            console.error(error);
          }
        }

  async getLabour(){
      try {
          let response = await fetch(
            'https://cmgmt.ntceg.org/labour', {
              method: 'GET',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
              },
            });
            let responseJson = await response.json();
            if(responseJson.status === "SuccessFull"){
              this.setState({labour: responseJson.Results});
            }
          } catch (error) {
          console.error(error);
        }
      }

  async getPhases(){
          try {
              let response = await fetch(
                'https://cmgmt.ntceg.org/phases', {
                  method: 'GET',headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                  },
                });

                let responseJson = await response.json();
                if(responseJson.status === "SuccessFull"){
                  this.setState({phases: responseJson.Results});
                }
              } catch (error) {
              console.error(error);
            }
          }

  alertNodeInfo({ node, path, treeIndex }){
    global.alert(
      'Node Material:'
    );
    this.setState({ zone: node.name });

  };

  copyFromNode({ node, path, treeIndex }){
    const getNodeKey = ({ treeIndex }) => treeIndex;
    var treeData1 = addNodeUnderParent({
      treeData: this.state.treeData,
      newNode: node,
      parentKey: path[path.length-2],
      getNodeKey
    });

    this.setState({ treeData: treeData1.treeData });
  };

  removeSelectedNode({ node, path, treeIndex }){
    const getNodeKey = ({ treeIndex }) => treeIndex;
    var treeData1 = removeNodeAtPath({
      treeData: this.state.treeData,
      path: path,
      getNodeKey,
    });

    this.setState({ treeData: treeData1 });
  };

  addItem(hasChildren){

    if(!hasChildren){
      let treeData1 = [];
      this.state.treeData.forEach(function(item){
        treeData1.push(item);
      });
      treeData1.push({name: this.state.node, noChildren: hasChildren});
      this.setState({ treeData: treeData1 });
      this.setState({node: ''});
    }else{
      let treeData1 = [];
      this.state.treeData.forEach(function(item){
        treeData1.push(item);
      });
      treeData1.push({name: this.state.node_title, zoneMaterials: this.state.materialVal, noChildren: hasChildren});
      this.setState({ treeData: treeData1 });
      this.setState({node: ''});
    }
  }

  updateState(e) {
    if(e.target.type === "select-multiple"){
      var resultArray = [];
      var resultArray2 = [];
      for(let i = 0;i<e.target.selectedOptions.length;i++){
        resultArray.push(e.target.selectedOptions[i].value);
      }
      for(let x = 0;x<e.target.selectedOptions.length;x++){
        resultArray2.push(e.target.selectedOptions[x].innerHTML);
      }
      this.setState({[e.target.name+"Val"]: resultArray});
      this.setState({[e.target.name+"Names"]: resultArray2})
    }else{
      this.setState({[e.target.name]: e.target.value});
    }
  }

  renderGeneratedButton(rowInfo){
    const getNodeKey = ({ treeIndex }) => treeIndex;

    if(rowInfo.node.noChildren){
      return({
        buttons: [
          <button
            className="btn"
            style={{
              verticalAlign: 'middle',
            }}
            onClick={() => this.alertNodeInfo(rowInfo)}
            >
            ℹ
          </button>,<button
              className="btn"
              style={{
                verticalAlign: 'middle',
              }}
              onClick={() => this.copyFromNode(rowInfo)}
              >
              Copy Tree
            </button>,<button
          className="btn"
          style={{
            verticalAlign: 'middle',
          }}
          onClick={() => this.removeSelectedNode(rowInfo)}
          >
          Delete Leaf
        </button>
      ],
      title: (
        <div>
        <input
          style={{ fontSize: '1.1rem' }}
          value={rowInfo.node.name}
          onChange={event => {
            const name = event.target.value;
            this.setState(state => ({
              treeData: changeNodeAtPath({
                treeData: state.treeData,
                path: rowInfo.path,
                getNodeKey,
                newNode: { ...rowInfo.node, name },
              }),
            }));
          }}
          />
          <input
            style={{ fontSize: '1.1rem' }}
            value={this.state.levels[rowInfo.path.length-1]}
            onChange={event => {
              const name = event.target.value;
              let levels1 = [];
              this.state.levels.forEach(function(item){
                levels1.push(item);
              });
              levels1[rowInfo.path.length-1] = name;
              this.setState({levels: levels1});
            }}
            />
      </div>
      )
    })
  }
  else{
    return({
      buttons: [
        <button
          className="btn"
          style={{
            verticalAlign: 'middle',
          }}
          onClick={() => this.copyFromNode(rowInfo)}
          >
          Copy Tree
        </button>,<button
        className="btn"
        style={{
          verticalAlign: 'middle',
        }}
        onClick={() => this.removeSelectedNode(rowInfo)}
        >
        Delete Tree
      </button>
    ],
    title: (
      <div>
      <input
        style={{ fontSize: '1.1rem' }}
        value={rowInfo.node.name}
        onChange={event => {
          const name = event.target.value;
          this.setState(state => ({
            treeData: changeNodeAtPath({
              treeData: state.treeData,
              path: rowInfo.path,
              getNodeKey,
              newNode: { ...rowInfo.node, name },
            }),
          }));
        }}
        /><input
          style={{ fontSize: '1.1rem' }}
          value={this.state.levels[rowInfo.path.length-1]}
          onChange={event => {
            const name = event.target.value;
            let levels1 = [];
            this.state.levels.forEach(function(item){
              levels1.push(item);
            });
            levels1[rowInfo.path.length-1] = name;
            this.setState({levels: levels1});
          }}
          />
    </div>
    ),
  })
}
}

render() {
  var materialArray = [];
  if(this.state.materials){
    materialArray = [];
    this.state.materials.map(item =>
      materialArray.push(<option key={item.id} value={item.id}>{item.name}</option>)
    );
  }

  var labourArray = [];
  if(this.state.labour){
    labourArray = [];
    this.state.labour.map(item =>
      labourArray.push(<option key={item.id} value={item.id}>{item.name}</option>)
    );
  }

  var phasesArray = [];
  if(this.state.phases){
    phasesArray = [];
    this.state.phases.map(item =>
      phasesArray.push(<option key={item.id} value={item.id}>{item.name}</option>)
    );
  }

  var userArray = [];
  if(this.state.users){
    userArray = [];
    this.state.users.map(item =>
      userArray.push(<option key={item.id} value={item.id}>{item.firstName + " " + item.lastName}</option>)
    );
  }

    return (
      <div className="col-12">
        <h3>Project Definition Screen</h3>
        <div className="input-group input-group-sm mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text" id="inputGroup-sizing-sm">Project Name:</span>
          </div>
          <input type="text" name="projectName" onChange={this.updateState} placeholder="projectname" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm"/>
        </div>
        <h4>Project Structure:</h4>
        <div>
          <div style={{height:300}}>
          <SortableTree
            treeData={this.state.treeData}
            onChange={treeData => this.setState({ treeData })}
            canDrop={({ nextParent }) => !nextParent || !nextParent.noChildren}
            generateNodeProps={rowInfo => this.renderGeneratedButton(rowInfo)}
            />
          </div>
          <div className="input-group mb-3">
            <input type="text" value={this.state.node} name="node" onChange={this.updateState} className="form-control" placeholder="Enter Node Name" aria-label="Recipient's username" aria-describedby="button-addon2"/>
            <div className="input-group-append">
              <button className="btn btn-outline-secondary" onClick={()=>this.addItem(false)} type="button" id="button-addon2">Add node</button>
            </div>
          </div>
          <div className="input-group mb-3">
            <input type="text" value={this.state.node_title} name="node_title" onChange={this.updateState} className="form-control" placeholder="Enter Node Name" aria-label="Recipient's username" aria-describedby="button-addon2"/>
              <select className="selectpicker" name="material" onChange={this.updateState} data-live-search="true" multiple>
                {materialArray}
              </select>
            <div className="input-group-append">
              <button className="btn btn-outline-secondary" onClick={()=>this.addItem(true)} type="button" id="button-addon2">Add Leaf</button>
            </div>
          </div>
          <div className="row">
          <div className="col-6">
          <h4>Project Labour:</h4>
            <div className="input-group input-group-sm mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-sm">Project Labour:</span>
              </div>
              <select className="selectpicker" name="labour" onChange={this.updateState} data-live-search="true" multiple>
                {labourArray}
              </select>
            </div>
            </div>
            <div className="col-6">
              <h4>Project Phases:</h4>
            <div className="input-group input-group-sm mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-sm">Project Phases:</span>
              </div>
              <select className="selectpicker" name="phase" onChange={this.updateState} data-live-search="true" multiple>
                {phasesArray}
              </select>
            </div>
          </div>
          </div>
          <div className="col-6">
          <h4>Project Users:</h4>
            <div className="input-group input-group-sm mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-sm">Project Engineers:</span>
              </div>
              <select className="selectpicker" name="users" onChange={this.updateState} data-live-search="true" multiple>
                {userArray}
              </select>
            </div>
            </div>
          <button type="button" className="btn btn-primary" onClick={this.submitProject}>Submit Project</button>
        </div>
      </div>
    );

}
}
