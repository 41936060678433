import React, { Component } from 'react';
import { ReactTabulator } from 'react-tabulator'
let token = sessionStorage.getItem('access_token');


var nameFormatted = function(cell, formatterParams, onRendered){
    return cell.getRow().getData()["records.labour.name"];
}

var priceFormatted = function(cell, formatterParams, onRendered){
    return parseInt(cell.getRow().getData().total) * parseInt(cell.getRow().getData()["records.labour.price"]);
}

export default class LabourReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labour: [],
      projectLabour: [],
      columns: [
        {title: "Zone Name", field: "name", width: 150,visible: false},
        {title: "Labour Name", formatter:nameFormatted},
        {title: "Labour Count", field: "total"},
        {title: "Labour Price", formatter:priceFormatted,bottomCalc:"sum"},
          ]
        }
        this.updateState = this.updateState.bind(this);
        this.getLabour = this.getLabour.bind(this);
      }

      async componentDidMount(){
        await this.getLabour();
      }

      async componentDidUpdate(prevProps){
        if(this.props.projectId !== prevProps.projectId){
          await this.getLabour();
        }
      }

      updateState(e) {
        this.setState({[e.target.name]: e.target.value});
      }

        async getLabour(){
          try {
            if(this.props.projectId !== "undefined"){
            let response = await fetch(
              'https://cmgmt.ntceg.org/reports/labour/'+ this.props.projectId, {
                method: 'GET',
                headers: {
                  'Authorization': 'Bearer ' + token,
                }
              });

              let responseJson = await response.json();
              if(responseJson.status === "SuccessFull"){
                this.setState({labour: responseJson.Results})
              }
            }else{
              this.setState({labour: []});
            }
            } catch (error) {
              console.error(error);
            }
          }

          render() {
            return (
              <ReactTabulator
                data={this.state.labour}
                columns={this.state.columns}
                options={{groupBy:["name","insertDate"],groupClosedShowCalcs:true}}
                layout={"fitData"}
                />
            );
          }
        }
